import makeBreakpointPadding from '@helpers/make-breakpoint-padding'
import { colorIsDark } from '@helpers/palette'
import { Box } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import PageContainer from '@system/page-container'
import ACC_THEME from '@themes'
import ACC_DARK_THEME from '@themes/dark'
import React from 'react'
import SbEditable from 'storyblok-react'

const makeBackgroundImageStyles = ({
  backgroundImage,
  renderBackgroundImage,
}) => {
  if (!renderBackgroundImage || !backgroundImage) return null

  return {
    backgroundColor: 'unset',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
}

const useStyles = makeStyles((theme) => ({
  twoColLayoutRoot: ({
    backgroundAppliesTo,
    backgroundImage,
    leftFlowsToBottom,
    shouldHaveBorder,
  }) => ({
    border: shouldHaveBorder ? `1px solid #C4C4C4` : null,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...makeBackgroundImageStyles({
      backgroundImage: backgroundImage?.filename,
      renderBackgroundImage: backgroundAppliesTo === 'full-width',
    }),
    [theme.breakpoints.down('sm')]: {
      flexDirection: `column${leftFlowsToBottom ? '-reverse' : ''}`,
    },
  }),
  left: ({
    backgroundAppliesTo,
    backgroundImage,
    leftBgColor,
    leftComponent,
    leftColWidth,
    gapWidth,
    hasContentContainer,
  }) => ({
    background: leftBgColor?.color,
    color:
      colorIsDark(leftBgColor?.color) || backgroundImage?.filename
        ? theme.palette.common.white
        : null,
    width: `${leftColWidth}%`,
    ...makeBackgroundImageStyles({
      backgroundImage: backgroundImage?.filename,
      renderBackgroundImage: backgroundAppliesTo === 'left',
    }),
    ...makeBreakpointPadding({ noRight: true, noLeft: !hasContentContainer }),
    [theme.breakpoints.up('md')]: {
      paddingRight: `${gapWidth / 2}% !important`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: !leftComponent ? '1.5rem' : '0 0 1.5rem 0 ',
      width: '100%',
    },
  }),
  right: ({
    backgroundAppliesTo,
    backgroundImage,
    gapWidth,
    hasContentContainer,
    leftColWidth,
    rightBgColor,
    rightComponent,
  }) => ({
    background: rightBgColor?.color,
    width: `${100 - leftColWidth}%`,
    ...makeBackgroundImageStyles({
      backgroundImage: backgroundImage?.filename,
      renderBackgroundImage: backgroundAppliesTo === 'right',
    }),
    ...makeBreakpointPadding({ noLeft: true, noRight: !hasContentContainer }),
    [theme.breakpoints.up('md')]: {
      paddingLeft: `${gapWidth / 2}% !important`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: !rightComponent ? '1.5rem' : '0',
      width: '100%',
    },
  }),
  divider: {
    borderLeft: '1px solid #C4C4C4',
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #C4C4C4',
      marginBottom: '2rem',
    },
  },
}))

const borderCheck = (backgroundImage, backgroundAppliesTo, hasBorder) => {
  if (!hasBorder) return false

  if (
    !backgroundImage?.filename ||
    backgroundAppliesTo === 'page-container' ||
    backgroundAppliesTo === 'full-width'
  ) {
    return true
  }

  return false
}

const TwoColumnLayoutModule = ({ blok, isPodcastModule, ...props }) => {
  const {
    backgroundAppliesTo,
    backgroundImage,
    container,
    divider,
    hasBorder,
    leftBgColor,
    leftColumn,
    rightBgColor,
    rightColumn,
  } = blok

  const classes = useStyles({
    hasContentContainer: container === 'contentContainer',
    leftComponent: leftColumn[0].component === 'pricingCardsModule',
    rightComponent: rightColumn[0].component === 'pricingCardsModule',
    shouldHaveBorder: borderCheck(
      backgroundImage,
      backgroundAppliesTo,
      hasBorder
    ),
    ...blok,
  })

  return (
    <SbEditable content={blok}>
      <PageContainer
        parentWidth={container === 'fullWidth'}
        styles={{
          width: '100%',
          ...makeBackgroundImageStyles({
            backgroundImage: backgroundImage?.filename,
            renderBackgroundImage: backgroundAppliesTo === 'page-container',
          }),
        }}
      >
        <Box className={classes.twoColLayoutRoot}>
          <ThemeProvider
            theme={
              (backgroundImage?.filename || colorIsDark(leftBgColor?.color)) &&
              (backgroundAppliesTo === 'full-width' ||
                backgroundAppliesTo === 'page-container' ||
                backgroundAppliesTo === 'left')
                ? ACC_DARK_THEME
                : ACC_THEME
            }
          >
            <Box className={classes.left}>
              {renderBloks(leftColumn, {
                isTwoColumnLayout: true,
                isPodcastModule,
              })}
            </Box>
          </ThemeProvider>
          {!!divider && <Box className={classes.divider}></Box>}
          <ThemeProvider
            theme={
              (backgroundImage?.filename || colorIsDark(rightBgColor?.color)) &&
              (backgroundAppliesTo === 'full-width' ||
                backgroundAppliesTo === 'page-container' ||
                backgroundAppliesTo === 'right')
                ? ACC_DARK_THEME
                : ACC_THEME
            }
          >
            <Box className={classes.right}>
              {renderBloks(rightColumn, {
                isTwoColumnLayout: true,
                isPodcastModule,
              })}
            </Box>
          </ThemeProvider>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default TwoColumnLayoutModule
